<script setup lang="ts">
import PageHeader from '@components/PageHeader/PageHeader.vue';
import MyAccount from '@components/Account/MyAccount.vue';
import OnboardingBanner from '@components/Onboarding/OnboardingBanner.vue';

import { useInvestorTestStore } from '@stores/useInvestorTestStore';
import { ref, computed, ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useModal, SidebarMenu, MobileMenu, CallMeBack, useToast } from '@mogelijk-technologies/ui-library';
import { useAuthStore } from '@stores/useAuthStore';
import { CallBack } from '@components/Contact/';
import { CREATE_CALLBACK } from '@graphql/mutations/callback';
import { useMutation } from '@urql/vue';

interface Props {
  title: string;
  showTimer?: boolean;
}

withDefaults(defineProps<Props>(), {
  showTimer: false,
});

const route = useRoute();
const router = useRouter();

const {
  authenticated,
  isInvestor,
  isFundInvestor,
  user,
  guardedRoutes,
} = toRefs(useAuthStore());

const { logout, getUserPhoneNumbers } = useAuthStore();

const {
  isProfessionalismTestInExperienced,
  isProfessionalismTestRejected,
  isProfessionalismTestPendingRequest,
} = toRefs(useInvestorTestStore());

interface NavigationLink {
  to: string;
  label: string;
  icon: string;
  notificationCount?: ComputedRef<number | undefined>;
}

const staticLinks: NavigationLink[] = [
  {
    to: '/aanbod',
    label: 'Actueel aanbod',
    icon: 'grid-2',
  },
];

const fundLinks = computed(() => {
  const links: NavigationLink[] = [];

  if (isFundInvestor.value) {
    links.push(
      {
        to: '/mogelijk-fonds',
        label: 'Mogelijk Fonds',
        icon: 'briefcase',
      },
    );
  }
  return links;
});

const partnerplanLinks = computed(() => {
  const links: NavigationLink[] = [];

  if (user.value?.is_mogelijk_partner) {
    links.push(
      {
        to: '/mijn-partnerplan',
        label: 'Mijn Partnerplan',
        icon: 'user-group-crown',
      },
    );
  }
  return links;
});


const mobileLinks = computed((): NavigationLink[] => {
  const mobileUrls = [
    {
      to: 'callMeBack',
      label: 'Bel me terug',
      icon: 'phone-arrow-down-left',
    },
  ];

  if (user.value) {
    mobileUrls.push(
      {
        to: '/logout',
        label: 'Uitloggen',
        icon: 'arrow-right-from-bracket',
      },
    );
  }

  return mobileUrls;
});

const links = computed(() => [...staticLinks, ...guardedRoutes.value, ...partnerplanLinks.value, ...fundLinks.value]);

const isOnboardingBannerShown = computed(() => isProfessionalismTestInExperienced.value || isProfessionalismTestRejected.value || isProfessionalismTestPendingRequest.value);
const isUserInvestor = computed(() => authenticated.value && isInvestor.value);
const isMobileMenuVisible = ref(false);

const getInitialCallBackValue = () => {
  const phoneNumbers = getUserPhoneNumbers();

  return {
    name: user.value ? user.value.name : '',
    phone: phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers[0] : '',
    callback_date: '',
    callback_time: '08:30',
    via: route ? route.meta.callMeBack as string : '',
  };
};

const callBack = ref<CallBack>();
const isCompleted = ref(false);

const { executeMutation: createCallBack, fetching: isFetching } = useMutation(CREATE_CALLBACK);

const requestCall = async (callback: CallBack) => {
  callBack.value = callback;
  let toast;
  const { data } = await createCallBack({ input: callback });

  if (data) {
    toast = await useToast({
      title: 'Succes',
      message: 'Terugbelverzoek succesvol ingediend',
      theme: 'success',
    });
  } else {
    toast = await useToast({
      title: 'Er is iets fout gegaan',
      message: 'Probeer het later opnieuw',
      theme: 'error',
    });
  }

  isCompleted.value = true;
  toast.present();
};

const setModal = () => {
  isCompleted.value = false;

  useModal({
    title: 'Terugbelverzoek',
    component: CallMeBack,
    props: {
      isFetching,
      isCompleted,
      modelValue: getInitialCallBackValue(),
    },
    events: {
      createCallBack: (callback: CallBack) => requestCall(callback),
    },
  });
};

const handleRoute = (to: string) => {
  isMobileMenuVisible.value = false;

  if (to === 'callMeBack') {
    setModal();
    return;
  }
  router.push(to);
};

const totalNotificationCount = computed(() => {
  let count = 0;
  links.value.forEach((item: NavigationLink) => {
    if (item.notificationCount?.value) {
      count += item.notificationCount.value;
    }
  });
  return count;
});

const isScrollUp = ref(true);

window.addEventListener('wheel', (event: WheelEvent) => {
  isScrollUp.value = (event.deltaY < 0);
});

</script>

<template>
  <div>
    <NuxtLayout name="default">
      <div class="mx-auto flex w-full grow flex-col 3xl:max-w-screen-2xl">
        <OnboardingBanner v-if="isUserInvestor && isOnboardingBannerShown" />
        <PageHeader
          :notification-count="totalNotificationCount"
          :title="title"
          :show-timer="showTimer"
          @set-menu-visible="() => {isMobileMenuVisible = true}"
        >
          <template #hamburger>
            <MobileMenu
              :items="[...links, ...mobileLinks]"
              :route="route.path"
              :is-menu-open-mobile="isMobileMenuVisible"
              class="tablet:hidden"
              @to="handleRoute"
              @logout="logout"
              @open-or-close-menu="isMobileMenuVisible = $event"
            >
              <div class="z-20 mb-2 rounded-3xl bg-white tablet:hidden" data-v-step-mobile="2">
                <MyAccount button-id="my-account-button-mobile" />
              </div>
            </MobileMenu>
          </template>
        </PageHeader>
        <div v-show="!isMobileMenuVisible" class="flex w-full grow px-4 tablet:gap-4">
          <!-- top 102px is the space for the header and 183px is the height of the footer + margin of the cards in the fund page -->
          <div v-if="user" class="fixed inset-y-0 z-40 h-[calc(100vh-183px)] tablet:sticky tablet:top-[102px]" :class="{'inset-x-0 !z-50': isMobileMenuVisible}">
            <SidebarMenu
              :items="links"
              :route="route.path"
              @to="handleRoute"
              @logout="logout"
            />
          </div>
          <main id="content" class="relative flex w-full grow flex-col pt-4 lg:pt-0">
            <div class="flex grow">
              <slot />
              <Contact class="fixed right-4 bottom-20 z-20" />
            </div>
          </main>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
